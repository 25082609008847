<template lang="pug">
v-container.body-1
  v-layout(row).display-1.font-weight-bold
    v-flex
      h3.mb-3 ink ticket

  v-layout(row).mb-5
    v-flex().title
      span.font-weight-bold {{company.name}} -
      | {{design.name}}

  v-layout(row v-if="design.image.url")
    v-flex(xs4).pl-3
      v-img(:src='design.image.url')


  v-layout.mt-5(row v-for='location in design.locations' :key='location._id')
    v-flex
      FwdCard
        template(slot="title") inks: {{location.template.name}}
        template(slot="content")
          InkList(:location='location') 

</template>

<script>
import InkList from '@/modules/designs/components/color-list'

export default {
  components: { InkList },
  computed: {
    company () { return this.$store.getters['companies/item'] },
    design () { return this.$store.getters['designs/item'] },
  },
  async created () {
    await this.$store.dispatch('designs/_LOAD', this.$route.params.id)
  }
}
</script>
